import React, { useEffect, useState } from "react"

import { inject, observer } from "mobx-react"
import { graphql } from "gatsby"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"
import { SidebarContents } from "../../components/modules/sidebar-contents/sidebar-contents"
import { CheckboxList } from "../../components/modules/checkbox-list/checkbox-list"
import { convertToId, redirectToAppropriateRegion } from "../../helpers/helpers"

import "./simple-step.scss"

export default inject("regionStore")(
  observer(({ regionStore, data }) => {
    const {
      title,
      subtitle,
      seoTitle,
      description,
      canonicalUrl,
      simpleStepContent,
      validInEngland,
      validInScotland,
    } = data.wagtail.page

    const [contentsHeaders, setContentsHeaders] = useState([])

    const { isEngland, isScotland } = regionStore

    const isNotRelevantToRegion =
      (isEngland && !validInEngland) || (isScotland && !validInScotland)

    useEffect(() => {
      const body = document.querySelector(".simple-step-body")
      addIdsToH2s(body)
      setContentsHeaders(
        [...body.querySelectorAll("h2")].map(({ innerHTML }) => innerHTML)
      )
    }, [])

    useEffect(() => {
      if (isNotRelevantToRegion) {
        redirectToAppropriateRegion(isEngland, isScotland)
      }
    })

    const addIdsToH2s = body => {
      const parsedBody = new DOMParser().parseFromString(
        body.innerHTML,
        "text/html"
      )
      const h2Elements = parsedBody.querySelectorAll("h2")
      for (let heading of h2Elements) {
        heading.setAttribute("id", convertToId(heading.textContent))
      }
      body.innerHTML = parsedBody.body.innerHTML
    }

    return (
      <Wrapper>
        <SEO
          title={`Simple Step - ${seoTitle}`}
          description={description}
          canonical={canonicalUrl}
        />

        {simpleStepContent && (
          <div className="row simple-step">
            <div className="col col-md-8 printable offset-md-3 mb-3">
              <h1 className="simple-step-title">
                {title}
                <span className="ml-3 font-weight-normal">{subtitle}</span>
              </h1>
            </div>

            <SidebarContents
              className="col-12 col-md-3 d-print-none"
              headers={contentsHeaders}
            />

            <div className="col offset-md-3 printable col-md-8 simple-step-body">
              {simpleStepContent.map((element, i) => {
                const type = element["__typename"]
                const isParagraph = type === "RichTextBlock"
                const isCheckboxList = type === "ListBlock"
                const isCallout = type === "StructBlock"

                if (isParagraph) {
                  return (
                    <div
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: element.rawValue
                          .replace(/<b>/g, "<strong>")
                          .replace(/<\/b>/g, "</strong>"),
                      }}
                    />
                  )
                } else if (isCheckboxList) {
                  return <CheckboxList key={i} items={element.items} />
                } else if (isCallout) {
                  const header = element.blocks[0].rawValue
                  const subheader = element.blocks[1].rawValue
                  const calloutStyle = element.blocks[2].rawValue
                  const body = element.blocks[3].rawValue

                  return (
                    <div key={i} className={`ss-${calloutStyle}`}>
                      <h3>{header}</h3>
                      {subheader && <h4>{subheader}</h4>}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: body
                            .replace(/<b>/g, "<strong>")
                            .replace(/<\/b>/g, "</strong>"),
                        }}
                      />
                    </div>
                  )
                }
              })}
            </div>
          </div>
        )}
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on SimpleStep {
          id
          seoTitle
          title
          subtitle
          description
          canonicalUrl
          validInEngland
          validInScotland
          simpleStepContent {
            ... on RichTextBlock {
              id
              rawValue
            }
            ... on StructBlock {
              blocks {
                ... on ChoiceBlock {
                  rawValue
                }
                ... on CharBlock {
                  rawValue
                }
                ... on RichTextBlock {
                  id
                  rawValue
                }
              }
            }
            ... on ListBlock {
              id
              items {
                rawValue
              }
            }
          }
        }
      }
    }
  }
`
