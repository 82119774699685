import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"

import { graphql } from "gatsby"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"
import {
  parsePythonObject,
  headerRegex,
  addHeaderIdAttribute,
  redirectToAppropriateRegion,
} from "../../helpers/helpers"

export default inject("regionStore")(
  observer(({ regionStore, data }) => {
    const {
      seoTitle,
      title,
      description,
      body,
      canonicalUrl,
    } = data.wagtail.page

    const { isEngland, isScotland } = regionStore

    useEffect(() => {
      redirectToAppropriateRegion(isEngland, isScotland)
    }, [])

    return (
      <Wrapper hasFeedbackForm={true}>
        <SEO
          title={seoTitle}
          description={description}
          canonical={canonicalUrl}
        />

        <div className="row">
          <div className="offset-md-2 col-md-8">
            <h1 className="mb-3">{title}</h1>

            {body.map(({ value, blockType }, index) => {
              if (blockType !== "TableBlock") {
                return (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: value
                        .replace(/<b>/g, "<strong>")
                        .replace(/<\/b>/g, "</strong>")
                        .replace(headerRegex, addHeaderIdAttribute),
                    }}
                  />
                )
              }

              const { data, first_row_is_table_header } = parsePythonObject(
                value
              )

              return (
                <div className="table-wrapper">
                  <table className="table" key={index}>
                    {first_row_is_table_header && (
                      <thead className="thead-dark">
                        <tr>
                          {data[0].map(element => (
                            <th key={element} scope="col">
                              {element}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    )}

                    <tbody>
                      {data
                        .filter((_, i) =>
                          first_row_is_table_header ? i !== 0 : true
                        )
                        .map((row, subIndex) => (
                          <tr key={subIndex}>
                            {row.map(element => (
                              <td key={element}>{element}</td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        </div>
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on BasicPage {
          id
          seoTitle
          title
          description
          canonicalUrl
          body {
            ... on RichTextBlock {
              blockType
              id
              value
            }
            ... on StreamFieldBlock {
              blockType
              id
              value
            }
          }
        }
      }
    }
  }
`
