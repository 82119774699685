import React from "react"

import { Link } from "gatsby"
import { observer, inject } from "mobx-react"

import { Wrapper } from "../components/base/wrapper/wrapper"
import { SEO } from "../components/base/seo/seo"
import { RegionToggle } from "../components/modules/region-toggle/region-toggle"

const NotFoundPage = inject("regionStore")(
  observer(({ regionStore }) => (
    <Wrapper>
      <SEO title="404: Not found" description="Page not found" />

      <div className="row">
        <div className="offset-2 col-8">
          <h1 className="mb-3">Sorry, we can't find that page</h1>

          <p>The page you're looking for may have been removed, taken down, or there could have been a problem with the link that brought you here.</p>
          
          {/* <h1 className="mb-3">Page Not Found</h1>

          <p>The page you are looking for cannot be found.</p>

          <p>
            The law about debt is different depending on whether you live in
            England and Wales or Scotland.
          </p>

          <p>
            <strong>
              {regionStore.currentRegionData && (
                <>
                  Your location is currently set to&nbsp;
                  {regionStore.currentRegionData.label}.
                </>
              )}
            </strong>
          </p>

          <p>
            If you live in&nbsp;
            <strong>{regionStore.alternateRegionData.label}</strong>, please
            change your location with the toggle below.
          </p>

          <RegionToggle className="mb-3" forceRefresh={true} />

          <p>
            If you live in <strong>Northern Ireland</strong>, please visit
            our&nbsp;
            <Link to="/northern-ireland">
              Northern Ireland information page.
            </Link>
          </p> */}

          <p>
            <strong>
              <Link to="/" aria-label="homepage">
                Click here
              </Link>
              &nbsp;to return to the homepage.
            </strong>
          </p>
        </div>
      </div>
    </Wrapper>
  ))
)

export default NotFoundPage
