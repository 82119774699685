import React, { useEffect } from "react"

import { graphql } from "gatsby"
import { inject, observer } from "mobx-react"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"
import { HeroBanner } from "../../components/modules/hero-banner/hero-banner"
import { CardGrid } from "../../components/layout/card-grid/card-grid"
import { redirectToAppropriateRegion } from "../../helpers/helpers"

export default inject("regionStore")(
  observer(({ regionStore, data }) => {
    const {
      seoTitle,
      title,
      ctaSubtitle,
      ctaDescription,
      description,
      canonicalUrl,
      body,
      showFeedbackBtn,
      image,
    } = data.wagtail.page

    const { isEngland, isScotland } = regionStore

    useEffect(() => {
      redirectToAppropriateRegion(isEngland, isScotland)
    }, [])

    return (
      <Wrapper hasFeedbackForm={showFeedbackBtn === "true"}>
        <SEO
          title={seoTitle}
          description={description}
          canonical={canonicalUrl}
        />

        <HeroBanner
          title={title}
          subtitle={ctaSubtitle}
          description={ctaDescription}
          backgroundImage={image}
        />

        <CardGrid body={body} />
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on GridPage {
          seoTitle
          title
          ctaSubtitle
          ctaDescription
          description
          canonicalUrl
          showFeedbackBtn
          image {
            src
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          body {
            ... on StructBlock {
              blocks {
                ... on ChoiceBlock {
                  rawValue
                }
                ... on StreamBlock {
                  blocks {
                    ... on StructBlock {
                      field
                      blocks {
                        field
                        blockType
                        ... on CharBlock {
                          field
                          value
                        }
                        ... on RichTextBlock {
                          rawValue
                          field
                        }
                        ... on BooleanBlock {
                          rawValue
                          field
                        }
                        ... on ChoiceBlock {
                          value
                          field
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
