import React from "react"

import { graphql } from "gatsby"
import { inject, observer } from "mobx-react"

import { SEO } from "../../components/base/seo/seo"
import { Wrapper } from "../../components/base/wrapper/wrapper"
import { HeroBanner } from "../../components/modules/hero-banner/hero-banner"
import { CardList } from "../../components/modules/card-list/card-list"

export default inject("regionStore")(
  observer(({ data, regionStore }) => {
    const { page, unmappedLetters } = data.wagtail
    const letters = unmappedLetters
      .filter(value => value["__typename"] === "SampleLetter")
      .filter(({ validInEngland, validInScotland }) =>
        regionStore.currentRegion === "EW" ? validInEngland : validInScotland
      )
      .map(({ title, url, categories }) => ({
        url,
        label: title,
        category: categories ? categories.name : "Uncategorised",
      }))
      .filter(({ category }) => category !== "Hidden")

    const categories = [...new Set(letters.map(({ category }) => category))]

    return (
      <Wrapper hasFeedbackForm={true}>
        <SEO
          title={page.seoTitle}
          description={page.description}
          canonical={page.canonicalUrl}
        />

        <HeroBanner
          title={page.title}
          subtitle={page.ctaHeader}
          description={page.ctaDescription}
          backgroundImage={page.ctaImage}
        />

        <div className="row h-100 mt-0 mb-0">
          {categories.map((category, i) => {
            const colWidth = i === 0 ? 6 : 3
            return (
              <div key={i} className={`d-flex col-12 mb-4 col-sm-${colWidth}`}>
                <CardList
                  title={category}
                  links={letters.filter(link => link.category === category)}
                />
              </div>
            )
          })}
        </div>
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on SampleLetterLibraryPage {
          seoTitle
          title
          description
          canonicalUrl
          ctaButtonAriaLabel
          ctaButtonText
          ctaButtonUrl
          ctaDescription
          ctaHeader
          ctaImage {
            src
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      unmappedLetters: pages {
        ... on SampleLetter {
          title
          url
          validInEngland
          validInScotland
          description
          categories {
            name
          }
        }
      }
    }
  }
`
