import React from "react"

import PropTypes from "prop-types"

import "./checkbox-list.scss"

export const CheckboxList = ({ items }) => (
  <ul className="checkbox-list">
    {items.map(({ rawValue }, i) => (
      <li key={i}>{rawValue}</li>
    ))}
  </ul>
)

CheckboxList.propTypes = {
  items: PropTypes.array,
}
