import React from "react"

import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./card-list.scss"

export const CardList = ({ title, links }) => (
  <div className="card card-list mb-sm-4">
    <div className="card-header">{title}</div>

    <div className={`card-body ${links ? "p-0" : ""}`}>
      {links ? (
        <ul className="list-group list-group-flush">
          {links.map(({ label, url }) => (
            <li key={label} className="list-group-item">
              <Link to={url} className="card-list-link">
                {label}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  </div>
)

CardList.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
}
