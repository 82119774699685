import React from "react"

import { Link } from "gatsby"
import { observer, inject } from "mobx-react"

import { Wrapper } from "../components/base/wrapper/wrapper"
import { SEO } from "../components/base/seo/seo"
import { RegionToggle } from "../components/modules/region-toggle/region-toggle"
import { isBrowser } from "../helpers/helpers"

const Scotland = inject("regionStore")(
  observer(({ regionStore }) => {
    regionStore.changeRegion("S")
    setTimeout(() => {
      if (isBrowser) {
        window.location.pathname = "/"
      }
    }, 250)

    return (
      <Wrapper>
        <SEO title="Scotland" description="Business Debtline Scotland" />

        <div className="row">
          <div className="offset-2 col-8">
            <h1 className="mb-3">Scotland</h1>

            <p>
              The law about debt is different depending on whether you live in
              England and Wales or Scotland. This page is for Scottish people.
            </p>

            <p>
              <strong>
                {regionStore.currentRegionData && (
                  <>
                    Your location is currently set to&nbsp;
                    {regionStore.currentRegionData.label}.
                  </>
                )}
              </strong>
            </p>

            <p>
              If you live in&nbsp;
              <strong>{regionStore.alternateRegionData.label}</strong>, please
              change your location with the toggle below.
            </p>

            <RegionToggle className="mb-3" forceRefresh={true} />

            <p>
              If you live in <strong>Northern Ireland</strong>, please visit
              our&nbsp;
              <Link to="/northern-ireland">
                Northern Ireland information page.
              </Link>
            </p>

            <p>
              <strong>
                <Link to="/" aria-label="homepage">
                  Click here
                </Link>
                &nbsp;to return to the homepage.
              </strong>
            </p>
          </div>
        </div>
      </Wrapper>
    )
  })
)

export default Scotland
