import React from "react"

import { graphql, Link } from "gatsby"
import { inject, observer } from "mobx-react"

import { Wrapper } from "../../components/base/wrapper/wrapper"
import { SEO } from "../../components/base/seo/seo"

import "./steps-root.scss"

export default inject("regionStore")(
  observer(({ data, regionStore }) => {
    const { page } = data.wagtail

    const steps = data.wagtail.steps
      .filter(step => step.title)
      .filter(({ validInEngland, validInScotland }) =>
        regionStore.currentRegion === "EW" ? validInEngland : validInScotland
      )
      .sort((a, b) => a.order - b.order)

    return (
      <Wrapper>
        <SEO
          title={page.seoTitle}
          description={page.description}
          canonical={page.canonicalUrl}
        />

        <div className="row">
          <div className="offset-1 col-10">
            <h1 className="mb-2">{page.title}</h1>
            <div className="mb-3">
              {page.body.map(({ rawValue }) => (
                <div
                  key={rawValue}
                  dangerouslySetInnerHTML={{
                    __html: rawValue
                      .replace(/<b>/g, "<strong>")
                      .replace(/<\/b>/g, "</strong>"),
                  }}
                />
              ))}
            </div>

            {steps.map(({ title, subtitle, url, order }) => (
              <Link
                key={title}
                className="steps-root-step d-flex align-items-center mb-2"
                to={url}
              >
                <h2 className="steps-root-step-title m-0">Step {order}</h2>
                <p className="steps-root-step-subtitle m-0 ml-4">{subtitle}</p>
              </Link>
            ))}
          </div>
        </div>
      </Wrapper>
    )
  })
)

export const query = graphql`
  query($slug: String) {
    wagtail {
      page(slug: $slug) {
        ... on SimpleStepsRootPage {
          title
          seoTitle
          description
          canonicalUrl
          body {
            ... on RichTextBlock {
              rawValue
            }
          }
        }
      }
      steps: pages {
        ... on SimpleStep {
          title
          subtitle
          order
          url
          validInEngland
          validInScotland
        }
      }
    }
  }
`
